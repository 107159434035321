@import 'styles/fonts.css';
@import 'styles/texts.css';
@import 'styles/layout.css';
@import 'styles/helpers.css';
@import 'styles/media.css';
@import 'styles/buttons.css';
@import 'styles/forms.css';

/* GENERIC */

html {
  font-size: 62.5% !important;
}

body {
  min-height: -webkit-fill-available;
}
