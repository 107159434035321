.circle {
  border-radius: 50%;
}

.width-100 {
  width: 100%;
}

.width-50 {
  width: 50%;
}

.height-100 {
  height: 100%;
}

.rotate-180 {
  transform: rotate(180deg);
}

.hidden {
  overflow: hidden;
}

.lateral-auto-margin {
  margin-left: auto;
  margin-right: auto;
}

.shadow {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.margin-0 {
  margin: 0;
}

.padding-0 {
  padding: 0;
}

.margin-auto {
  margin: auto;
}

.margin-vertical-auto {
  margin: auto 0;
}

.margin-top-auto {
  margin-top: auto;
}

.pointer {
  cursor: pointer;
}

.regular-border {
  border: 1px solid var(--ion-color-border);
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.overflow-auto {
  overflow: auto;
}
