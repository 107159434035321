:root {
  --ion-default-font: 'Nunito Sans';
  --ion-font-family: 'Nunito Sans';
}

:root {
  --ion-color-primary: #7500E9;
  --ion-color-primary-shade: #f9f3ff;
  --ion-color-primary-darker: #8e1eff;
  --ion-color-input-bg: #f5f6fa;
  --ion-color-dark-text: #140025;
  --ion-color-light-text: #707070;
  --ion-color-gray-text: #585858;
  --ion-color-red: #ff5757;
  --ion-color-black: #000000;
  --ion-color-light-green: #BCF7EB;
  --ion-color-dark-green: #00322A;
  --ion-color-light-blue: #46e2ff;
  --ion-color-light-blue-light: #46e2ff1a;
  --ion-color-java: #1ad7e2;
  --ion-color-java-light: #1ad7e21a;
  --ion-color-yellow: #fdd158;
  --ion-color-yellow-light: #fdd1581a;
  --ion-color-orange: #f8a949;
  --ion-color-orange-light: #f8a9491a;
  --ion-color-salmon: #fd836e;
  --ion-color-salmon-light: #fd836e1a;
  --ion-color-rose: #f962a7;
  --ion-color-rose-light: #f962a71a;
  --ion-color-purple: #a162f8;
  --ion-color-purple-light: #a162f81a;
  --ion-color-dribo-purple: #8b30ff;
  --ion-color-blue: #6f88fc;
  --ion-color-blue-light: #6f88fc1a;
  --ion-toolbar-color: var(--ion-color-dark-text);
  --ion-color-dove-gray: #666666;
  --ion-color-silver: #bcbcbc;
  --ion-color-light-silver: #e8e8e8;
  --ion-color-white: #ffffff;
  --ion-color-warning: #ff5345;
  --ion-color-disabled: #cbcbcb;
  --ion-color-light-gray: #f5f5f5;
  --ion-color-light-border: rgba(112, 112, 112, 0.1);
  --ion-color-border: rgba(112, 112, 112, 0.3);
  --default-box-shadow: 0px -1px 4px #707070;
  --ion-color-light-gray: #f5f5f5;
  --ion-color-light-red: #ffeeed;
  --ion-color-toast-info-icon: #e8e8e8;

  --toastify-spinner-color: #7500E9;
  --toastify-spinner-color-empty-area: #F1E6FD;

  --base-black: #140025;

  --grey-grey-21: #F8F7F8;
  --grey-grey-3: #F6F5F6;
  --grey-grey-4: #F1F0F2;
  --grey-grey-5: #DCD9DE;
  --grey-grey-6: #c4bfc9;
  --grey-grey-8: #665971;
  --grey-grey-10: #372646;
  --grey-grey-11: #301F3F;
  --grey-grey-41: #EBE9EB;

  --primary-primary-25: #F1E6FD;
  --primary-primary-50: #E2CAFA;
  --primary-primary-500: #7500E9;
  --primary-primary-700: #5300A5;

  --auxiliares-blue-1: #D8EAFE;
  --auxiliares-blue-2: #333F4D;
  --auxiliares-green-1: #BCF7EB;
  --auxiliares-green-2: #00322A;
  --auxiliares-red-1: #FFA5A4;
  --auxiliares-red-2: #862F2F;
  --auxiliares-yellow-1: #FFE397;
  --auxiliares-yellow-2: #5B5032;

  --notificaciones-green: #348372;
  --notificaciones-red: #CC3633;
}

ion-header,
ion-header ion-toolbar {
  height: 4.8rem;
  --border-width: 0 !important;
  --min-height: 1rem !important;
}
ion-header.header-md:after {
  background: none;
}

.avatar-color-1 {
  background-color: var(--ion-color-light-blue);
}

.avatar-color-2 {
  background-color: var(--ion-color-java);
}

.avatar-color-3 {
  background-color: var(--ion-color-yellow);
}

.avatar-color-4 {
  background-color: var(--ion-color-orange);
}

.avatar-color-5 {
  background-color: var(--ion-color-salmon);
}

.avatar-color-6 {
  background-color: var(--ion-color-rose);
}

.avatar-color-7 {
  background-color: var(--ion-color-purple);
}

.avatar-color-8 {
  background-color: var(--ion-color-blue);
}

.chip-color-1 {
  background-color: var(--ion-color-light-blue-light);
  color: var(--ion-color-light-blue);
}

.chip-color-2 {
  background-color: var(--ion-color-java-light);
  color: var(--ion-color-java);
}

.chip-color-3 {
  background-color: var(--ion-color-yellow-light);
  color: var(--ion-color-yellow);
}

.chip-color-4 {
  background-color: var(--ion-color-orange-light);
  color: var(--ion-color-orange);
}

.chip-color-5 {
  background-color: var(--ion-color-salmon-light);
  color: var(--ion-color-salmon);
}

.chip-color-6 {
  background-color: var(--ion-color-rose-light);
  color: var(--ion-color-rose);
}

.chip-color-7 {
  background-color: var(--ion-color-purple-light);
  color: var(--ion-color-purple);
}

.chip-color-8 {
  background-color: var(--ion-color-blue-light);
  color: var(--ion-color-blue);
}

.fill-white {
  fill: var(--ion-color-white);
}

.toast-icon-bg {
  background-color: var(--ion-color-toast-info-icon);
}

ion-backdrop {
  background: rgba(20, 0, 37);
  opacity: 0.4;
}