html,
body,
ion-button span {
  font-family: 'Nunito Sans';
}

h1,
h2,
h3 {
  margin: 0;
}

.title-1 {
  font-weight: 800;
  color: var(--ion-color-dark-text);
  font-size: 2rem;
  line-height: 2.5rem;
}

.supertitle {
  font-weight: 400;
  color: var(--ion-color-dark-text);
  font-size: 2.3rem;
}

.main-text {
  color: var(--ion-color-dark-text);
  font-size: 1.6rem;
}

.small-text {
  color: var(--ion-color-light-text);
  font-size: 1.3rem;
}

.small-title {
  font-size: 1.3rem;
  text-transform: uppercase;
  color: var(--ion-color-dark-text);
  line-height: 2rem;
  font-weight: 700;
}

.dark {
  color: var(--ion-color-dark-text);
  --color: var(--ion-color-dark-text);
}

.light {
  color: var(--ion-color-light-text);
  --color: var(--ion-color-light-text);
}

.white {
  color: var(--ion-color-white);
  --color: var(--ion-color-white);
}

.primary {
  color: var(--ion-color-primary);
  --color: var(--ion-color-primary);
}

.color-black {
  color: var(--ion-color-black);
  --color: var(--ion-color-black);
}

.main-text-large {
  color: var(--ion-color-dark-text);
  font-size: 1.8rem;
}

.main-text-dark {
  color: var(--ion-color-dark-text);
  font-size: 1.6rem;
}

.main-text-small {
  color: var(--ion-color-light-text);
  font-size: 1.4rem;
}

.medium-text {
  font-weight: 300;
  color: var(--ion-color-dark-text);
  font-size: 1.6rem;
}

.medium-text-small {
  font-weight: 300;
  color: var(--ion-color-dark-text);
  font-size: 1.5rem;
}

.medium-important {
  font-size: 1.6rem !important;
}

.small {
  font-size: 0.8rem;
}

.small-paragraph-title {
  font-size: 0.9rem;
  line-height: 1.5rem;
  font-weight: 700;
}

.small-paragraph-text {
  font-weight: 400;
  color: var(--ion-color-dove-gray);
  --color: var(--ion-color-dove-gray);
  font-size: 1.4rem;
  line-height: 1.7rem;
}

.item-title {
  font-size: 1.8rem !important;
  color: var(--ion-color-dark-text);
  margin: 0 !important;
}

.item-subtitle {
  font-size: 1.7rem;
  color: var(--ion-color-light-text);
}

.info-text {
  font-size: 1.7rem;
  color: var(--ion-color-gray-text);
  margin: 0;
}

.regular-text {
  font-size: 1.7rem;
  color: var(--ion-color-dark-text);
  line-height: 2.4rem;
}

.warning {
  font-size: 1.6rem;
  color: var(--ion-color-warning);
}

.link-text {
  color: var(--ion-color-dribo-purple);
}

.section-title {
  font-size: 1.2rem;
  line-height: 2rem;
  color: var(--ion-color-black);
  font-weight: 700;
  letter-spacing: 0.014rem;
}

.regular-button-text {
  font-size: 1.5rem;
  line-height: 1.8rem;
  font-weight: 400;
  color: var(--ion-color-white);
}

.button-text {
  font-size: 1.7rem;
  line-height: 2rem;
  font-weight: 500;
}

.medium {
  font-weight: 300;
}

.black {
  font-weight: 800;
}

.bold {
  font-weight: 700;
}

.semibold {
  font-weight: 600 !important;
}

.heavy {
  font-weight: 900;
}

.center {
  text-align: center;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.ellipsis {
  text-overflow: ellipsis;
}

span.error {
  font-size: 1.2rem;
  color: var(--ion-color-red);
}

.none-transform {
  text-transform: none;
}

.no-letter-spacing {
  letter-spacing: 0;
}

.chip {
  border-radius: 1.5rem;
  padding: 0.6rem 0.8rem;
  font-size: 1.3rem;
  line-height: 1.7rem;
  font-weight: 600;
}

.chip.small {
  padding: 0.3rem 0.7rem;
  height: 2.1rem;
}

.warning-chip {
  border: 1px solid;
  border-color: var(--ion-color-warning);
  background-color: var(--ion-color-light-red);
  color: var(--ion-color-warning);
  border-radius: 1.5rem;
  padding: 0.6rem 0.8rem;
  font-size: 1.3rem;
  line-height: 1.8rem;
  font-weight: 600;
}

.warning-chip.round {
  width: 2rem;
  height: 2rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 2rem;
  text-align: center;
  border-radius: 50%;
  padding: 0;
  z-index: 1;
  right: 1rem;
}
