.action-sheet-group:first-child {
  border-radius: 10px 10px 0 0;
}

ion-action-sheet.car-options {
  --button-color-selected: var(--ion-color-primary);
}

.car-options .action-sheet-selected.sc-ion-action-sheet-md {
  font-weight: 400;
}
