/*POSITION*/

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.absolute-centered-lateral {
  position: absolute;
  bottom: inherit;
  left: 50%;
  transform: translateX(-50%);
}

.right {
  right: 0;
}

.left {
  left: 0;
}

.bottom {
  bottom: 0;
}

/*BLOCK*/

.block {
  display: block;
}

/*FLEX*/

.flex-horizontal {
  display: flex;
  padding: 0 !important;
  flex-wrap: wrap;
  align-content: flex-start;
}

.flex-vertical {
  display: flex;
  flex-direction: column;
}

.flex {
  display: flex !important;
}

.flex-1 {
  flex: 1;
}
.between {
  justify-content: space-between;
}

.even {
  justify-content: space-evenly;
}

.justify-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.align-items-baseline {
  align-items: baseline;
}

.align-items-end {
  align-items: end;
}
