.ion-fab-button-smaller {
  width: 2.5rem;
  height: 2.5rem;
  font-size: 1.3rem;
}

.action-button {
  margin: 0;
}

.action-button::part(native) {
  padding: 0;
  border-radius: 50%;
  width: 4.1rem;
  height: 4.1rem;
}

.action-button ion-icon {
  width: 2.2rem;
  height: 2.2rem;
  margin: auto;
}

ion-back-button {
  display: block;
}

ion-back-button::part(icon) {
  width: 2.4rem;
  height: 2.4rem;
  color: var(--base-black);
}

ion-back-button.ios::part(icon) {
  margin-left: 1.3rem;
}

ion-button {
  text-transform: none;
  letter-spacing: 0;
}

ion-button.button-disabled {
  --background: var(--ion-color-disabled);
  --color: var(--ion-color-light-text);
}

ion-button.button-outline.thin {
  --border-width: 0.1rem;
}

ion-button.border-black {
  --border-color: var(--ion-color-dark-text);
  color: var(--ion-color-dark-text);
}

ion-button.main-button {
  height: 4.6rem;
  font-size: 1.6rem;
  text-transform: none;
}

ion-button.padding-0::part(native) {
  padding: 0;
}
