ion-avatar.large {
  width: 4.4rem;
  height: 4.4rem;
}

.card {
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
  padding: 1rem;
  border-radius: 5px;
}

ion-spinner {
  top: 20%;
  left: calc(50% - 2rem);
  --color: var(--ion-color-primary);
}

ion-icon.circle {
  border-radius: 50%;
  padding: 0.75rem;
  color: var(--ion-color-dark-text);
  background-color: var(--ion-color-light-gray);
}

.icon-container.circle {
  border-radius: 50%;
  padding: 0.75rem;
  color: var(--ion-color-dark-text);
  background-color: var(--ion-color-light-gray);
}

ion-modal.signature-pad-modal {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.6);
}

ion-modal.signature-pad-modal .modal-shadow {
  display: none;
}

ion-modal.signature-pad-modal .modal-wrapper {
  --height: 80%;
  margin-top: auto;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
}

ion-modal.custom-alert-modal .modal-wrapper {
  width: 35.1rem;
  height: max-content;
  border-radius: 1.2rem;
}

ion-modal.custom-alert-modal .ion-page {
  padding: 3.2rem 1.6rem;
  position: relative;
  height: 100%;
  contain: none;
}

ion-action-sheet.teacher-action-sheet .action-sheet-group {
  padding: 1.5rem 0 !important;
}

ion-action-sheet.teacher-action-sheet .action-sheet-group .action-sheet-button span {
  font-size: 1.6rem;
  color: var(--ion-color-dark-text);
}

ion-action-sheet.teacher-action-sheet .action-sheet-group .action-sheet-button span ion-icon {
  margin-right: 1.5rem;
  width: 2rem;
  height: 2rem;
  padding-bottom: 0;
  color: var(--ion-color-dark-text);
}

/*TOAST*/

.Toastify__toast-container {
  border-radius: 1rem !important;
  margin: 5.4rem 1.6rem 0 1.6rem !important;
  width: calc(100vw - 3.2rem) !important;
  top: 0;
  left: unset;
  transform: unset;
}

.Toastify__toast {
  min-height: unset;
  border-radius: 1rem !important;
  align-items: center;
  padding: 1.2rem 1.6rem;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
}

.Toastify__toast.not-appeared-form-toast {
  position: absolute;
  bottom: 9rem;
}

.Toastify__toast-body {
  margin: 0 !important;
  height: max-content !important;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  color: var(--grey-grey-11);
  padding: 0 !important;
  gap: 0.8rem;
}

.Toastify__toast-icon {
  margin-inline-end: 0px !important;
  width: unset;
}


.Toastify__toast-icon .Toastify__spinner {
  margin: auto;
  width: 2rem;
  height: 2rem;
}


/*RADIO BUTTON*/

ion-radio-group ion-item {
  --background-activated: transparent !important;
  --ripple-color: transparent !important;
}

/*OTHER*/

.separator {
  width: 100%;
  height: 11px;
  min-height: 11px;
  background: #f4f5fa;
}
