@font-face {
  font-family: 'Nunito Sans';
  src: url('../assets/fonts/NunitoSans-VariableFont_YTLC,opsz,wdth,wght.ttf') format('truetype');
  font-weight: 100 900;
  font-style: normal;
  font-variation-settings: 'opsz' 12, 'wdth' 100, 'wght' 400;
}


@font-face {
  font-family: 'Nunito Sans';
  src: url('../assets/fonts/NunitoSans-Italic-VariableFont_YTLC,opsz,wdth,wght.ttf') format('truetype');
  font-weight: 100 900;
  font-style: italic;
  font-variation-settings: 'opsz' 12, 'wdth' 100, 'wght' 400;
}

.material-symbols-outlined {
  font-size: 2rem;
}

html {
  font-family: 'Nunito Sans', sans-serif;
}


:root {
  --toastify-font-family: 'Nunito Sans';
}
